import { ITIM } from 'interfaces/ITIM';
import { ITimInput } from 'interfaces/inputs/ITimInput';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import { isBoolean } from 'lodash';

const createIdObject = (id: number | undefined) => (id ? { id } : null);

export const buildTIMPayload = (
  tim: ITimInput,
  unitOfMeasurement: string,
): ITIM => {
  const isSqmMeasurement = unitOfMeasurement === UnitOfMeasurement.sm;
  const payload: ITIM = {};

  payload.id = tim.id;
  payload.activeSinceDate = tim.activeSinceDate;
  payload.clearHeightHigh = tim.clearHeightHigh;
  payload.clearHeightHighMt = tim.clearHeightHighMt;
  payload.clearHeightLow = tim.clearHeightLow;
  payload.clearHeightLowMt = tim.clearHeightLowMt;
  payload.currentPropertyId = tim.currentPropertyId;
  payload.driveInDoors = tim.driveInDoors;
  payload.exitDate = tim.exitDate;
  payload.loadingDocks = tim.loadingDocks;
  payload.targetSizeMin = tim.targetSizeMin;
  payload.targetSizeMinMt = tim.targetSizeMinMt;
  payload.targetSizeMax = tim.targetSizeMax;
  payload.targetSizeMaxMt = tim.targetSizeMaxMt;
  payload.trailerParkingSpaces = tim.trailerParkingSpaces;
  payload.newToMarket = isBoolean(tim.newToMarket) ?? null;
  payload.targetCommencementDate = tim.targetCommencementDate;
  payload.currentLeaseId = tim.currentLeaseId;
  payload.currentSize = tim.currentSize;
  payload.currentSizeMt = tim.currentSizeMt;
  payload.newToMarket = tim.newToMarket;
  payload.source = tim.source;
  payload.comments = tim.comments;
  payload.lastDiscussedDate = tim.lastDiscussedDate;

  payload.dockConfigurationType = createIdObject(tim.dockConfigurationTypeId);
  payload.targetMarket = createIdObject(tim.targetMarket?.id);
  payload.targetPropertyType = createIdObject(tim.targetPropertyTypeId);
  payload.tenantCompany = createIdObject(
    tim.tenantCompanyId || tim.tenantCompany?.id,
  );
  payload.timStatusType = createIdObject(tim.timStatusTypeId);
  payload.targetPropertySubType = createIdObject(tim.targetPropertySubTypeId);
  payload.targetThirdPartyPropertyClass = createIdObject(
    tim.targetThirdPartyPropertyClassId,
  );
  payload.tenantRepBrokerCompany = createIdObject(
    tim.tenantRepBrokerCompany?.id,
  );
  payload.currentMarket = createIdObject(tim.currentMarket?.id);

  payload.expandingContracting = tim?.expandingContracting
    ? { id: tim.expandingContracting.id, name: tim.expandingContracting.name }
    : null;
  payload.targetSubmarkets = tim.targetSubmarkets?.length
    ? tim.targetSubmarkets.map(s => ({ id: s.id }))
    : null;
  payload.tenantRepBroker =
    tim.tenantRepBrokerName || tim.tenantRepBroker?.name
      ? {
          name: tim.tenantRepBrokerName ?? tim.tenantRepBroker?.name,
        }
      : null;

  if (isSqmMeasurement) {
    delete payload.clearHeightHigh;
    delete payload.clearHeightLow;
    delete payload.targetSizeMin;
    delete payload.targetSizeMax;
    delete payload.currentSize;
  } else {
    delete payload.clearHeightHighMt;
    delete payload.clearHeightLowMt;
    delete payload.targetSizeMinMt;
    delete payload.targetSizeMaxMt;
    delete payload.currentSizeMt;
  }

  return payload;
};
